import React from 'react';
import { getTag } from '../../../utils/getTag';
import { HeadingStyles } from '../styles/HeroContent.styles';
import { AnimationHeadingStyle } from '../styles/HeroNewStyles';
import { removeHeadingList, splitHeadingList } from '../utils/helpers';

const HeroHeading = ({ heading, hasCycleText, headingSize, colorMap }) => {
  const wordList = splitHeadingList(heading);
  const updatedHeading = hasCycleText ? removeHeadingList(heading) : heading;
  const headingTagFallback = hasCycleText ? 'h2' : 'h1';
  const Heading = headingSize ? getTag(headingSize) : headingTagFallback;

  // check if the header has a comma in it
  // -> split into an array if it does to render in stacks.
  const headingHasComma = hasCycleText
    ? updatedHeading.join('').includes(',')
    : updatedHeading.includes(',');
  let headingSplitByComma = [];

  if (headingHasComma) {
    if (hasCycleText) {
      updatedHeading.forEach(cycleTextChunk => {
        headingSplitByComma = [
          ...headingSplitByComma,
          cycleTextChunk.split(',').map(chunk => chunk.trim()),
        ];
      });
    } else {
      headingSplitByComma = updatedHeading
        .split(',')
        .map(chunk => chunk.trim());
    }
  }

  // splits the heading and adds a comma to each item, except for the last.
  const formatSplitHeading =
    headingHasComma &&
    headingSplitByComma.map((c, idx) =>
      idx < headingSplitByComma.length - 1 ? c.concat(',') : c
    );
  if (hasCycleText) {
    return (
      <HeadingStyles color={colorMap?.heading}>
        <Heading style={{ whiteSpace: 'pre-line' }}>
          {updatedHeading[0].replace('\\n', '\n')}
        </Heading>
        <AnimationHeadingStyle cnt={wordList.length}>
          {wordList.map(headingText => (
            <span key={headingText}>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop
                      offset="0%"
                      style={{ stopColor: '#1C3FFF', stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: '#1C3FFF', stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
                <text
                  fill="url(#grad1)"
                  fontSize="54.93"
                  fontFamily="CircularXX-Bold"
                  x="0"
                  y="70%"
                >
                  {headingText}
                </text>
              </svg>
            </span>
          ))}
        </AnimationHeadingStyle>

        <Heading>{updatedHeading[1]}</Heading>
      </HeadingStyles>
    );
  }

  if (headingHasComma) {
    return (
      <HeadingStyles color={colorMap?.heading}>
        {formatSplitHeading.map((text, key) => (
          <Heading key={`hk_${key + 1}_${text}`}>{text}</Heading>
        ))}
      </HeadingStyles>
    );
  }

  return (
    <HeadingStyles color={colorMap?.heading}>
      <Heading>{heading}</Heading>
    </HeadingStyles>
  );
};

export default HeroHeading;
